<template>
  <nav class="navbar" v-bind:class="{ 'game-page-navbar': $route.path.includes('/play/') }">
    <div class="nav-items-container">
      <div class="nav-item">
        <!-- Site Logo -->
        <router-link to="/" class="navbar-logo">
          <img src="@/assets/logo.png" alt="Site Logo" />
          <span class="navbar-site-title"><b>DED GAMES</b></span>
        </router-link>
      </div>

      <div class="nav-item nav-item-navbar-menu">
        <!-- Menu Items -->
        <ul class="navbar-menu">
          <li v-for="item in menuItems" :key="item.name">
            <router-link :to="item.path" class="menu-item" :class="{ active: $route.path === item.path }">
              {{ item.name }}
            </router-link>
          </li>
          <li>
            <a href="https://t.me/dedbird" target="_blank" class="menu-item telegram-link">
              Join our Telegram
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
  const menuItems = [
    { name: 'Play DED MINE', path: '/play/dedmine' }
    // { name: 'Join our Telegram', path: 'https://t.me/dedbird' }
  ]

  function isGamePage() {
    const currentUrl = window.location.href;
    return currentUrl.includes('/play/');
  }

</script>

<style>

.navbar {
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  transform: rotate(-1.5deg);
}

.navbar::before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;

  top: -100px;
  bottom: 0;
  left: -100px;
  right: 0;
  background-color: black;

}


@media (max-height: 600px) {
  .game-page-navbar {
    display: none;
  }
}

.nav-items-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.navbar-logo {
  display: inline-block;
  height: 50px;
}

.navbar-site-title {
  display: inline-block;
  line-height: 50px;
  vertical-align: top;
  margin-left: 20px;
  font-size: 40px;
  color: #ffc200;
  white-space: nowrap;

  text-shadow: 2px 2px 0px #ff007a;
}

.navbar-logo img {
  height: 50px; /* Adjust as needed */
  vertical-align: top;
}

.navbar-menu {
  list-style: none;
/*  display: flex;*/
  margin: 0;
  padding: 0;
  margin-top: 5px;
/*  float: right;*/
}
.navbar-menu ul, {
  display: block;
  flex-wrap: wrap;
}
.navbar-menu li {
  display: inline-block;
  white-space: nowrap;
  padding: 10px 0;
}

.nav-item-navbar-menu {
  display: flex;
  float: right;
}


@media (max-width: 732px) {
  .navbar-menu {
    margin-top: 35px;
    padding-bottom: 20px;
  }
  .navbar {
    padding: 5px;
  }
  .nav-item {
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.menu-item {
  color: white;
  font-weight: bold;
  margin-left: 40px; /* Adjust spacing as needed */
  text-decoration: none;
}

.menu-item.active {
  color: pink;
}
</style>