<template>
  <div class="home">

    <div class="block-intro">

      <div class="intro-content">
        <h2>Didn't you get the memo?</h2>

        <h1>
          Gaming is DED
        </h1>

        <h3>Let's celebrate the death of gaming<br>by playing the first game on our platform.</h3>

        <button class="play-button" @click="this.$router.push('/play/dedmine')">
          Play DED MINE
        </button>

      </div>
    </div>


<!--     <div class="block-footer">
    </div> -->


  </div>
</template>

<style>
/*  .home {
    font-family: 'Honk';
  }*/

  body {
    margin: 0;
  }


  .block-intro {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;    
    min-height: max(100vh, 1200px);
  }
  .block-intro::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('@/assets/backgrounds/pixelated_background_01.jpg');
    background-size: cover;
    background-attachment: fixed;
    opacity: 0.6;
  }

  .intro-content {
    position: relative;
    width: 100%;
    transform: translate(0, max(25vh, 250px));
    vertical-align: middle;
  }

  .intro-content h1 {
    font-size: 60px;
    color: #ffca20;
    text-shadow: 4px 4px 0px #ff38f9;
  }

  .intro-content h2, .intro-content h3, .play-button {
    font-family: 'Dong';
    font-size: 30px;
    color: #ffffff;
    text-shadow: 1px 1px 0px #ff007a;

  }

  .intro-content {
    font-family: 'Dong';
  }

  .play-button {
    margin-top: 20px;

    /* Basic Button Styling */
    padding: 30px 50px;
    border: none;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    outline: none;

    /* Pixelated Gradient Background */
    background: #ffca2033;
    background-size: 20px 20px;

    border: 2px solid #ffca20;

    /* Hover Effect */
    transition: background-size 0.3s ease;

  }
  .play-button:hover {
    background: #ffca2088;
  }


</style>

<script setup lang="ts">

</script>
